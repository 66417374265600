import React from "react"
import {css} from "@emotion/core"
import {useSelector} from "react-redux"
import {Container} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import selectors from "lib/redux/selectors"
import cssVars from "styles/variables.module.scss"
import withErrorModal from "../../lib/hocs/with-error-modal"
import CoinIcon from "./coin.svg"

const styles = {
  container: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0',
    '.body': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      borderBottom: `1px solid ${cssVars.gray500}`,
      padding: '1.2rem',
      ".icon": {
        width: "6rem",
        height: "6rem",
        color: cssVars.green,
        marginBottom: '0.8rem',
      },
      ".points": {
        color: cssVars.green,
      }
    },
    '.registered-users-only': {
      padding: '0.8rem',
    },
  }),
}

/**
 * Flow Step 0 for Takeaway&Delivery and Product checkout
 */
function FlowStepCampaignOfferCode({
                                     onFormikProps,
                                     onNextStep,
                                     showModal,
                                   }) {
  const {t} = useTranslation()

  const { points, premiumPoints } = useSelector(selectors.product.info)
  const campaign = useSelector((state) => state.campaign.info)

  if (campaign.obtainMethod !== 'qrcode_scan') {
    showModal(t("campaignErrors.invalid"))

    onFormikProps({
      // Disable the global "submit to next step" button
      submitForm: null,
      submitLabel: null,
    })
  } else {
    onFormikProps({
      submitForm: () => {
        onNextStep()
      },
      isValid: true,
      submitLabel: t("flow.obtainCampaign"),
    })
  }

  return (
    <Container css={styles.container}>
      <div className="body">
        <CoinIcon
          className="icon"
        />

        <p className="obtain">{t('campaignInfo.obtainPointsCampaign')}</p>
        <p className="points">{points} {t('campaignInfo.points')}</p>
      </div>
      <p className="registered-users-only">{t('campaignInfo.registeredUsers')}</p>
    </Container>
  )
}

export default withErrorModal(FlowStepCampaignOfferCode)
