import {useTranslation} from "react-i18next"
import React, {useCallback, useEffect, useState} from "react"
import actions from "lib/redux/actions"
import selectors from "lib/redux/selectors";
import {useDispatch, useSelector} from "react-redux"
import {css} from "@emotion/core"
import {Button, Form, Modal, Spinner} from "react-bootstrap"
import cssVars from "../../styles/variables.module.scss"
import {IconWrapper} from "../icons"
import * as yup from "yup"
import {useFormik} from "formik"
import useFormikEffect from "../useFormikEffect"
import {validateNIF} from "../../lib/helpers/vat"
import i18n from "i18next"

const styles = {
  spinnerContainer: css({
    position: "absolute",
    zIndex: 10,
    width: '100%',
    minHeight: '200px',
    maxHeight: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,.8)",
  }),
  addressesContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '6px',
    width: '100%',
    ".divider": {
      borderBottom: `1px solid ${cssVars.gray500}`,
    },
    ".link": {
      padding: 0,
      textDecoration: "underline"
    },
    ".add-address": {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '8px',
      alignItems: 'center',
      padding: '0.2rem',
    },
    ".address": {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '8px',
      alignItems: 'center',
      padding: '0.3rem',
      borderRadius: '8px',
      border: `1px solid ${cssVars.gray500}`,
      "&.select": {
        cursor: 'pointer',
      },
      "&.selected": {
        border: `2px solid ${cssVars.primary}`,
      },
      "&.invalid": {
        border: `2px solid ${cssVars.danger}`,
      },
      ".addressDetails": {
        flexGrow: 1,
        ".name": {
          marginBottom: 0,
          fontSize: '1rem',
          fontWeight: 400,
        },
        ".details": {
          marginBottom: 0,
          fontSize: '0.8rem',
        },
      },
    },
  }),
  addressLineRow: css({
    display: "flex",
    columnGap: "16px",
    ".country": {
      flex: "100%",
    },
    ".street": {
      flex: "65%",
    },
    ".floorDoor": {
      flex: "35%",
    },
    ".postalCode": {
      flex: "50%",
    },
    ".city": {
      flex: "50%",
    },
  }),
  addressForm: css({
    ".modal-footer": {
      justifyContent: "end",
    },
  }),
}

export const addressSchema = yup.object({
  street: yup.string().min(1).required(),
  city: yup.string().min(1).required(),
  postalCode: yup.string().when("countryId", {
    is: (countryId) => parseInt(countryId) === 139, //Portugal,
    then: (schema) => schema.matches(/^\d{4}-\d{3}$/).required(),
    otherwise: (schema) => schema.required(),
  }),
  vatId: yup.string().when("countryId", {
    is: (countryId) => parseInt(countryId) === 139, //Portugal,
    then: (schema) => schema
      .test('validator-custom-name', (value, { createError, path }) => {
        if (!value || (!!value && value.length > 0 && validateNIF(value))) {
          return true
        }

        return createError({
          path,
          message: i18n.t('forms.errors.vatIdInvalid'),
        })
      }),
    otherwise: (schema) => schema.nullable(),
  }),
})

export default function AddressSelection({ fiscal, value, invalid, onChange }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const addresses = useSelector(selectors.addresses.addresses)
  const addressState = useSelector(selectors.addresses.address)
  const deliveryCountries = useSelector(selectors.deliveries.countries)
  const [isLoading, setIsLoading] = useState(false)
  const [showAddressForm, setShowAddressForm] = useState(false)

  const selectedAddress = addresses.find(address => address.id === value)

  const {
    id,
    addressName,
    countryId,
    city,
    postalCode,
    street,
    floorDoor,
    company,
    vatId,
  } = addressState

  useEffect(() => {
    fetchAddresses()
    // fetch countries in which shop delivers to
    dispatch(actions.deliveries.fetchDeliveryCountries({}))
  }, [])

  const fetchAddresses = useCallback(async () => {
    await setIsLoading(true)
    const result = await dispatch(actions.addresses.fetchAddresses())
    const addresses = result.value?.data
    if(addresses && addresses.length === 1) {
      onChange(addresses[0])
    }
    await setIsLoading(false)
  }, [])

  const updateAddressState = useCallback((data) => {
      dispatch(actions.addresses.setAddressState({ data }))
    }, []);

  const createUpdateAddress = useCallback(async (values) => {
    await setIsLoading(true)
    const result = await dispatch(actions.addresses.createUpdateAddress(values))
    if (result.value && result.value.data.id) {
      await onChange(result.value.data)
    }
    await setIsLoading(false)
    await onHideAddressForm()
  }, [])

  const handleFormChange = useCallback(({ nextValues }) => {
    // check if delivery rates need to be updated
    // TODO: check if values changed
    updateAddressState(nextValues);
  }, [updateAddressState]);

  // this form is for create/update only
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    submitForm,
    isSubmitting,
    values,
    touched,
    isValid,
    errors,
    setValues,
    setTouched,
  } = useFormik({
    initialValues: {
      id,
      addressName,
      countryId,
      city,
      postalCode,
      street,
      floorDoor,
      company,
      vatId,
    },
    onSubmit: createUpdateAddress,
    validationSchema: addressSchema,
  })

  useFormikEffect(handleFormChange, { values })

  const showNewAddressForm = useCallback(() => {
    resetForm()
    setShowAddressForm(true)
  }, [resetForm, setShowAddressForm])

  const editAddress = useCallback(async (address) => {
    //TODO: move to object?
    await setValues({
      id: address.id,
      addressName: address.addressName,
      countryId: address.countryId,
      city: address.city,
      postalCode: address.postalCode,
      street: address.street,
      floorDoor: address.floorDoor ? address.floorDoor : '',
      company: address.company ? address.company : '',
      vatId: address.vatId ? address.vatId : '',
    })
    await setTouched({
      addressName: true,
      countryId: true,
      city: true,
      postalCode: true,
      street: true,
      floorDoor: false,
      company: false,
      vatId: true,
    }, true)

    await setShowAddressForm(true)
  }, [updateAddressState, setShowAddressForm])

  const showSelectAddress = useCallback(() => {
    onChange(null)
  }, [updateAddressState])

  const selectAddress = useCallback((address) => {
    onChange(address)
  }, [updateAddressState])

  const onHideAddressForm = useCallback(async () => {
    await setShowAddressForm(false)
    await resetForm()
  })

  return (
    <>
      {isLoading && (
        <div css={styles.spinnerContainer}>
          <Spinner animation="border" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </Spinner>
        </div>
      )}
      {(!isLoading && selectedAddress) && (
        <div css={styles.addressesContainer}>
          <div className="add-address">
            <Button className="link" variant="none" type="button" onClick={showSelectAddress}>
              {t('delivery.changeAddress')}
            </Button>
          </div>
          <div className={invalid ? 'address invalid' : 'address selected'}>
            <IconWrapper name="mdiHome" size="1.6rem"/>
            <div className="addressDetails">
              <p className="name">{selectedAddress.addressName}</p>
              <p className="details">
                {selectedAddress.street + (selectedAddress.floorDoor ? `, ${selectedAddress.floorDoor}` : '')}
              </p>
              <p className="details">{`${selectedAddress.postalCode}, ${selectedAddress.city}`}</p>
              {fiscal && (
                <p className="details">{selectedAddress.vatId}</p>
              )}
            </div>
            <Button className="link" variant="none" type="button" onClick={() => editAddress(selectedAddress)}>
              {t('delivery.editAddress')}
            </Button>
          </div>
        </div>
      )}
      {(!isLoading && !selectedAddress) && (
        <div css={styles.addressesContainer}>
          {addresses.map(address => (
            <div
              key={`address-${address.id}`}
              className="address select"
              onClick={() => selectAddress(address)}
            >
              <IconWrapper name="mdiHome" size="1.6rem"/>
              <div className="addressDetails">
                <p className="name">{address.addressName}</p>
                <p className="details">
                  {address.street + (address.floorDoor ? `, ${address.floorDoor}` : '')}
                </p>
                <p className="details">{`${address.postalCode}, ${address.city}`}</p>
                {(fiscal && address.vatId) && (
                  <p className="details">NIF: {address.vatId}</p>
                )}
              </div>
            </div>
          ))}
          <div className="add-address">
            <IconWrapper name="mdiHome" size="1.6rem"/>
            <Button className="link" variant="none" type="button" onClick={showNewAddressForm}>
              {t('delivery.addAddress')}
            </Button>
          </div>
        </div>
      )}

      {showAddressForm && (
        <Modal show centered onHide={onHideAddressForm} css={styles.addressForm}>
          <Modal.Body>
            <Form.Group css={styles.addressLineRow}>
              <div className="country">
                <Form.Control
                  type="text"
                  className="rounded-underline"
                  name="addressName"
                  placeholder={t("forms.addressName")}
                  value={values.addressName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.addressName && !!errors.addressName}
                />
                {touched.addressName && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.addressNameInvalid")}
                  </Form.Control.Feedback>
                )}
              </div>
            </Form.Group>
            <Form.Group css={styles.addressLineRow}>
              <div className="country">
                <Form.Control
                  as="select"
                  className="rounded-underline"
                  name="countryId"
                  placeholder={t("forms.country")}
                  value={values.countryId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.countryId && !!errors.countryId}
                >
                  {deliveryCountries.map((country) => (
                    <option key={`country-${country.id}`} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
                {touched.street && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.countryInvalid")}
                  </Form.Control.Feedback>
                )}
              </div>
            </Form.Group>
            <Form.Group css={styles.addressLineRow}>
              <div className="street">
                <Form.Control
                  className="rounded-underline"
                  type="text"
                  name="street"
                  placeholder={t("forms.street")}
                  value={values.street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.street && !!errors.street}
                />
                {touched.street && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.streetInvalid")}
                  </Form.Control.Feedback>
                )}
              </div>
              <div className="floorDoor">
                <Form.Control
                  className="rounded-underline"
                  type="text"
                  name="floorDoor"
                  placeholder={t("forms.floorDoor")}
                  value={values.floorDoor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.floorDoor && !!errors.floorDoor}
                />
                {touched.floorDoor && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.floorDoorInvalid")}
                  </Form.Control.Feedback>
                )}
              </div>
            </Form.Group>
            <Form.Group css={styles.addressLineRow}>
              <div className="postalCode">
                <Form.Control
                  className="rounded-underline"
                  type="text"
                  name="postalCode"
                  placeholder={t("forms.postalCode")}
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.postalCode && !!errors.postalCode}
                />
                {touched.postalCode && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.postalCodeInvalid")}
                  </Form.Control.Feedback>
                )}
              </div>
              <div className="city">
                <Form.Control
                  className="rounded-underline"
                  type="text"
                  name="city"
                  placeholder={t("forms.city")}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.city && !!errors.city}
                />
                {!!errors.city && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.cityInvalid")}
                  </Form.Control.Feedback>
                )}
              </div>
            </Form.Group>

            <Form.Group css={styles.company}>
              <Form.Control
                className="rounded-underline"
                type="text"
                name="company"
                placeholder={t("forms.company")}
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.company && !!errors.company}
              />
              {touched.company && (
                <Form.Control.Feedback type="invalid">
                  {t("forms.errors.companyInvalid")}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group css={styles.vatId}>
              <Form.Control
                className="rounded-underline"
                type="text"
                name="vatId"
                placeholder={t("forms.vatId")}
                value={values.vatId}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.vatId && !!errors.vatId}
              />
              {touched.vatId && (
                <Form.Control.Feedback type="invalid">
                  {t("forms.errors.vatIdInvalid")}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="outline-dark" onClick={onHideAddressForm}>
              {t('forms.cancel')}
            </Button>
            <Button
              disabled={!isValid || isSubmitting}
              variant="primary"
              onClick={submitForm}
            >
              {t('forms.save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
