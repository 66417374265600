import Cookies from "js-cookie"

export const getAndUpdateRwgToken = (rwgToken, shopId) => {
  if (typeof shopId === 'undefined') {
    return
  }

  const cookieToken = getRwgToken()

  if ((rwgToken && rwgToken !== cookieToken) || !cookieToken) {
    Cookies.set(rwgKey(), rwgToken, { expires: 30 })
    Cookies.set(shopKey(), shopId, { expires: 30 })
  }
}

export const removeRwgCookies = () => {
  Cookies.remove(rwgKey())
  Cookies.remove(shopKey())
}

const rwgKey = () => {
  const envPrefix = process.env.ENV === 'production' ? '' : process.env.ENV
  return `${envPrefix}_rwg_token`
}

const shopKey = () => {
  const envPrefix = process.env.ENV === 'production' ? '' : process.env.ENV
  return `${envPrefix}_shop_id`
}

export const getRwgToken = () => {
  return Cookies.get(rwgKey())
}

export const getRwgShopId = () => {
  return Cookies.get(shopKey())
}

