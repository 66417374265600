import api from "lib/api";

const CAMPAIGN_FETCH = "CAMPAIGN_FETCH";

const fetchCampaign = ({ campaignId, offerCode }) => (dispatch, getState) => {
  const state = getState()

  if (typeof campaignId === "undefined") {
    return dispatch({
      type: CAMPAIGN_FETCH,
      payload: api.campaign.getByOfferCode({
        offerCode,
        entityId: state.entity.entityId,
        language: state.context.language,
      })
    })
  }

  return dispatch({
    type: CAMPAIGN_FETCH,
    payload: api.campaign.get({
      campaignId,
      entityId: state.entity.entityId,
      language: state.context.language,
    })
  });
}

fetchCampaign.action = CAMPAIGN_FETCH;

export default fetchCampaign;
