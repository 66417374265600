import React, { useCallback, useEffect, useState, useMemo } from "react";
import {useDispatch, useSelector} from "react-redux"
import { css } from "@emotion/core";
import { Alert, Container, Form, Nav, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { triggerEvent } from "lib/analytics";
import actions from "lib/redux/actions";
import withErrorModal from "lib/hocs/with-error-modal";
import {
  checkNativeAppleLogin, checkNativeFacebookLogin,
  checkNativeGoogleLogin, isAppleLoginAvailable, tryNativeAppleLogin,
  tryNativeFacebookLogin,
  tryNativeGoogleLogin,
} from "lib/helpers/inter-frame-communication"
import { useTranslation, Trans } from "react-i18next";
import api from "lib/api";
import LoginSocialButton from "components/elements/login-social-button";
import cssVars from "styles/variables.module.scss";
import {getLocalizedPrivacyPolicyUri, getLocalizedTermsAndConditionsUri} from "lib/helpers/i18n"
import Title from "./title";
import selectors from "../../lib/redux/selectors"

const styles = {
  submitButton: css({
    textTransform: "uppercase",
    margin: "2rem 0"
  }),
  checkBox: css({
    fontSize: "0.8rem",
    color: cssVars.gray600
  }),
  fbButtonContainer: css({
    textAlign: "center",
    ".fb-share-policy": {
      marginTop: "1rem",
      fontSize: "0.875rem"
    }
  }),
  separator: css({
    display: "flex",
    alignItems: "center",
    margin: "2rem 0",
    "&::before, &::after": {
      content: "''",
      flex: 1,
      height: "1px",
      backgroundColor: cssVars.gray500
    },
    "&::before": {
      marginRight: "1rem"
    },
    "&::after": {
      marginLeft: "1rem"
    }
  }),
  tabs: css({
    marginBottom: "2rem",
    justifyContent: "center",
    borderBottom: "none",
    ".nav-link": {
      color: cssVars.gray600,
      border: "none",
      "&.active": {
        color: cssVars.primary,
        fontWeight: "bold",
        background: "none"
      }
    }
  })
};

const PASSWORD_MIN_CHARS = 6;

function AuthEmailSocial({ email, onFormikProps, onComplete, onForgotPassword, showModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(true);
  const [socialLoggingIn, setSocialLoggingIn] = useState(false);
  const [facebookLoginAvailable, setFacebookLoginAvailable] = useState(false);
  const [googleLoginAvailable, setGoogleLoginAvailable] = useState(false);
  const [appleLoginAvailable, setAppleLoginAvailable] = useState(false);
  const [hideGoogleSignInButton, setHideGoogleSignInButton] = useState(false)
  const entityEnv = useSelector(selectors.entity.env)

  const loginPasswordSchema = yup.string().required();
  const registerPasswordSchema = yup
    .string()
    .min(PASSWORD_MIN_CHARS)
    .required();
  const acceptConditionsSchema = yup.bool().oneOf([true]);

  const referralCode = useSelector(selectors.flow.referralCode);

  const privacyPolicyUrl = `${entityEnv.frontendUrl}${getLocalizedPrivacyPolicyUri()}`;

  const termsAndConditionsUrl = `${entityEnv.frontendUrl}${getLocalizedTermsAndConditionsUri()}`

  const handlePrivacyPolicy = useCallback(async (event) => {
    if (isCordovaAppClient) {
      event.preventDefault();
      try {
        await navigateTo(getLocalizedPrivacyPolicyUri());
      } catch {
        window.open(privacyPolicyUrl, '_blank').focus();
      }
    }
  }, [privacyPolicyUrl]);

  const handleTermsAndCondition = useCallback(async (event) => {
    if (isCordovaAppClient) {
      event.preventDefault();
      try {
        await navigateTo(getLocalizedTermsAndConditionsUri());
      } catch {
        window.open(termsAndConditionsUrl, '_blank').focus();
      }
    }
  }, [termsAndConditionsUrl]);

  const schema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email()
          .required(),
        password: isLogin ? loginPasswordSchema : registerPasswordSchema,
        acceptConditions: isLogin ? undefined : acceptConditionsSchema
      }),
    [isLogin]
  );

  const handleFacebookLoginStatus = useCallback(response => {
    let authResponse;
    if (response.status === "connected") {
      authResponse = response.authResponse;
    }
    if (response.accessToken) {
      authResponse = response;
    }
    if (authResponse) {

      const data = {
        accessToken: authResponse.accessToken,
        expiresIn: authResponse.expiresIn,
        persistence: true
      }

      if (authResponse.authenticationToken && authResponse.authenticationToken !== 'undefined') {
        data.authenticationToken = authResponse.authenticationToken
      }

      dispatch(
        actions.user.loginWithFacebook(data)
      )
        .then(response => {
          // Don't do anything because we'll be logged in in the redux store
          // and the parent component will move on to the next state automatically
          const {
            id,
            districtId,
            genderId,
            newRegistration
          } = response.action.payload.data;
          triggerEvent(
            "Purchase",
            "successful_login_booking_widget",
            "facebook",
            {
              dimension5: districtId,
              dimension6: id,
              dimension7: genderId
            }
          );

          triggerEvent(
            "Purchase",
            "Successful_login_registration_booking_widget",
            newRegistration ? "Facebook_registration" : "Facebook_login"
          );
        })
        .catch(err => {
          setSocialLoggingIn(false);

          showModal(err.message);
          triggerEvent("Purchase", "error_login_booking_widget", err.message);
          triggerEvent("Purchase", "Error_login_registration_first_step", err.message);
        });
    } else {
      setSocialLoggingIn(false);
      triggerEvent(
        "Purchase",
        "Error_login_registration_first_step",
        response.status === "unknown"
          ? "User not logged into Facebook, closed login popup"
          : `Facebook response status: ${response.status}`
      );
    }
  }, []);

  const handleFbLoginClick = useCallback(() => {
    triggerEvent(
      "Purchase",
      "Click_continue_login_registration_first_step",
      "Facebook"
    );

    setSocialLoggingIn(true);

    // First try to log in with the Facebook lib of the parent frame.
    // We might be running inside the Cordova app and this allows us to use
    // the native Facebook SDK
    tryNativeFacebookLogin()
      .then(handleFacebookLoginStatus)
      .catch((err) => {
        if (!err || (err.status !== 404 && err.status !== 501)) {
          setSocialLoggingIn(false);
          return;
        }
        // No native Facebook SDK found in the parent.
        // Fallback to the widget's own web-based Facebook login
        window.FB.login(handleFacebookLoginStatus, {
          scope: "public_profile,email",
        });
      });
  }, []);

  const handleGoogleLoginStatus = useCallback(response => {
    const credential = response.credential || response.id_token

    if (credential) {
      dispatch(
        actions.user.loginWithGoogle({
          credential: credential,
          persistence: true
        })
      ).then(response => {
        setSocialLoggingIn(false)
        // Don't do anything because we'll be logged in in the redux store
        // and the parent component will move on to the next state automatically
        const {
          id,
          districtId,
          genderId,
          newRegistration
        } = response.action.payload.data;
        triggerEvent(
          "Purchase",
          "successful_login_booking_widget",
          "google",
          {
            dimension5: districtId,
            dimension6: id,
            dimension7: genderId
          }
        );

        triggerEvent(
          "Purchase",
          "Successful_login_registration_booking_widget",
          newRegistration ? "Google_registration" : "Google_login"
        );
      })
      .catch(err => {
        setSocialLoggingIn(false);

        showModal(err.message);
        triggerEvent("Purchase", "error_login_booking_widget", err.message);
        triggerEvent(
          "Purchase",
          "Error_login_registration_first_step",
          err.message
        );
      });
    } else {
      setSocialLoggingIn(false);
      triggerEvent(
        "Purchase",
        "Error_login_registration_first_step",
        response.status === "unknown"
          ? "User not logged into Google, closed login popup"
          : `Google response status: ${response.status}`
      );
    }
  }, []);

  const handleGoogleLoginClick = useCallback(() => {
    triggerEvent(
      "Purchase",
      "Click_continue_login_registration_first_step",
      "Google"
    );

    setSocialLoggingIn(true);

    // First try to log in with the Facebook lib of the parent frame.
    // We might be running inside the Cordova app and this allows us to use
    // the native Facebook SDK
    tryNativeGoogleLogin()
      .then((obj) => {
        if (typeof obj === 'string') {
          const data = JSON.parse(obj)
          if (data && data.message) {
            handleGoogleLoginStatus(data.message)
          } else {
            setSocialLoggingIn(false);
          }
        } else {
          // new non-string format
          if (obj.result.idToken) {
            handleGoogleLoginStatus({
              credential: obj.result.idToken
            })
          } else {
            setSocialLoggingIn(false);
          }
        }
      })
      .catch((err) => {
        if (!err || (err.status !== 404 && err.status !== 501)) {
          setSocialLoggingIn(false);
          return;
        }
        // No native Google SDK found in the parent.
        // Fallback to the widget's own web-based google login
        // which in this case we have no control over click action...
      });
  }, []);

  const handleAppleLoginStatus = useCallback((code, idToken, firstName, lastName) => {
    if (code && idToken) {
      dispatch(
        actions.user.loginWithApple({
          code: code,
          idToken: idToken,
          persistence: true
        })
      )
        .then(response => {
          // Don't do anything because we'll be logged in in the redux store
          // and the parent component will move on to the next state automatically
          const {
            id,
            districtId,
            genderId,
            newRegistration
          } = response.action.payload.data;
          triggerEvent(
            "Purchase",
            "successful_login_booking_widget",
            "apple",
            {
              dimension5: districtId,
              dimension6: id,
              dimension7: genderId
            }
          );

          triggerEvent(
            "Purchase",
            "Successful_login_registration_booking_widget",
            newRegistration ? "Apple_registration" : "Apple_login"
          );
        })
        .catch(err => {
          setSocialLoggingIn(false);

          showModal(err.message);
          triggerEvent("Purchase", "error_login_booking_widget", err.message);
          triggerEvent(
            "Purchase",
            "Error_login_registration_first_step",
            err.message
          );
        });
    } else {
      setSocialLoggingIn(false);
      triggerEvent(
        "Purchase",
        "Error_login_registration_first_step",
        response.status === "unknown"
          ? "User not logged into Apple, closed login popup"
          : `Apple response status: ${response.status}`
      );
    }
  }, []);

  const handleAppleLoginClick = useCallback(() => {
    triggerEvent(
      "Purchase",
      "Click_continue_login_registration_first_step",
      "Apple"
    );

    setSocialLoggingIn(true);

    // check apple native plugin
    checkNativeAppleLogin()
      .then(result => {
        // use native apple plugin inside app
        if (result) {
          tryNativeAppleLogin()
            .then(response => {
              // check format for old responses
              if (response.authorizationCode) {
                const { authorizationCode, identityToken, fullName } = response
                const { givenName, familyName } = fullName || {}
                handleAppleLoginStatus(authorizationCode, identityToken, givenName || null, familyName || null)
              } else {
                const { accessToken, idToken, profile } = response
                const { givenName, familyName } = profile || {}

                handleAppleLoginStatus(idToken, accessToken.token, givenName || null, familyName || null)
              }
            })
            .catch((err) => {
              if (!err || (err.status !== 404 && err.status !== 501)) {
                setSocialLoggingIn(false);
                return;
              }
            });
        }
      })
      .catch(() => {
        // in web - init appleId js and sign in
        window.AppleID.auth.init({
          clientId : entityEnv.appleSignInClientId,
          scope : 'name email',
          redirectURI : 'https://' + location.hostname + '/auth/callback?apple_login=1',
          state : 'User login request',
          nonce : '[NONCE]',
          usePopup : true,
        });

        window.AppleID.auth.signIn().then(response => {
          if (response && response.authorization) {
            const { code, id_token } = response.authorization
            handleAppleLoginStatus(code, id_token, null, null)
          } else {
            // no authorization code
            setSocialLoggingIn(false);
          }
        }).catch(function(error) {
          // error
          setSocialLoggingIn(false);
        })
    })
  }, []);

  useEffect(() => {
    triggerEvent("Purchase", "Open_login_registration_first_step");
    if (window.FB && entityEnv.facebookAppId) {
      window.FB.init({
        appId: entityEnv.facebookAppId,
        status: false,
        xfbml: true,
        version: "v5.0"
      });
      setFacebookLoginAvailable(true);
    } else {
      checkNativeFacebookLogin()
        .then(result => {
          if (result) {
            setFacebookLoginAvailable(true)
          }
        })
    }

    // check if we have native plugin available, otherwise init google account js

    checkNativeGoogleLogin()
      .then(result => {
        // use native google plugin - show custom button
        if (result) {
          setGoogleLoginAvailable(true)
          setHideGoogleSignInButton(true)
        }
      }).catch(() => {
        // if native plugin not available init google account and render button
        if (window.google.accounts && entityEnv.googleSignInWebClientId) {
          window.google.accounts.id.initialize({
            client_id: entityEnv.googleSignInWebClientId,
            callback: (response) => {
              if (response) {
                setSocialLoggingIn(true)
                handleGoogleLoginStatus(response)
              }
            }
          });
          window.google.accounts.id.renderButton(
            document.getElementById('g_id_signin'),
            {
              theme: "outline",
              size: "large",
              width: 280,
              shape: 'rectangular',
            }
          );
        }
    })

    isAppleLoginAvailable()
      .then(result => {
        // use native apple plugin inside app
        setAppleLoginAvailable(result)
      })
      .catch(() => {
        // error thrown if no parent - Apple login available
        // Apple login only doesn't work in android app
        // should still work in browser
        setAppleLoginAvailable(!!entityEnv.appleSignInClientId)
      })
  }, []);

  const handleValidSubmit = async ({ email, password }) => {
    if (isLogin) {
      try {
        const response = await dispatch(
          actions.user.login({
            username: email,
            password,
            persistence: true
          })
        );
        const { id, districtId, genderId } = response.action.payload.data;
        triggerEvent("Purchase", "successful_login_booking_widget", "mygon", {
          dimension5: districtId,
          dimension6: id,
          dimension7: genderId
        });
        triggerEvent(
          "Purchase",
          "Successful_login_registration_booking_widget",
          "Email_login"
        );
      } catch (err) {
        showModal(err.message);
        triggerEvent("Purchase", "error_login_booking_widget", err.message);
        return false;
      }
    } else {
      try {
        const response = await dispatch(
          actions.user.register({
            username: email,
            password,
            referralCode,
          })
        );
        const { id } = response.action.payload.data;
        const { districtId } = response.action.meta;
        // Since the API doesn't return the logged in user token, we actually
        // have to log in the user after signup. :/
        await dispatch(
          actions.user.login({
            username: email,
            password,
            persistence: true
          })
        );
        triggerEvent(
          "Purchase",
          "successful_registration_booking_widget",
          "mygon",
          {
            dimension5: districtId,
            dimension6: id
          }
        );
        triggerEvent(
          "Purchase",
          "Successful_login_registration_booking_widget",
          "Email_registration"
        );
      } catch (err) {
        showModal(err.message);
        triggerEvent(
          "Purchase",
          "error_registration_booking_widget",
          err.message
        );
        return false;
      }
    }
    onComplete();
    return false;
  };

  return (
    <Formik
      validateOnMount
      validationSchema={schema}
      initialValues={{
        email: email || "",
        password: "",
        acceptConditions: false
      }}
      onSubmit={handleValidSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        submitForm,
        isValid,
        values,
        touched,
        errors,
        isSubmitting
      }) => {
        onFormikProps({
          // Disable the global "submit to next step" button
          submitForm: null,
          submitLabel: null,
          isValid,
          isSubmitting,
          waitingOverlay: socialLoggingIn
        });
        return (
          <Form
            noValidate
            className="form padded-step-container"
            onSubmit={handleSubmit}
          >
            <Container>
              <Nav variant="tabs" css={styles.tabs}>
                <Nav.Item>
                  <Nav.Link
                    active={isLogin}
                    onClick={() => setIsLogin(true)}
                  >
                    {t("Login")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={!isLogin}
                    onClick={() => setIsLogin(false)}
                  >
                    {t("forms.register")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Form.Group>
                <Title>{isLogin ? t("forms.titles.login") : t("forms.titles.register")}</Title>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  className="rounded-underline"
                  type="email"
                  name="email"
                  style={{ marginBottom: "0.5rem" }}
                  placeholder={t("forms.email")}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
                {touched.email && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.emailInvalid")}
                  </Form.Control.Feedback>
                )}
                <Form.Group>
                  <Form.Control
                    className="rounded-underline"
                    type="password"
                    name="password"
                    placeholder={t("forms.password")}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  {touched.password && errors.password && (
                    <Form.Control.Feedback type="invalid">
                      {isLogin
                        ? t("forms.errors.passwordLogin")
                        : t("forms.errors.passwordRegister", {
                            count: PASSWORD_MIN_CHARS,
                          })}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Group>
              {isLogin ? (
                <Form.Group>
                  <a
                    href="#"
                    onClick={() => {
                      triggerEvent(
                        "Purchase",
                        "Click_forget_my_password_booking_widget"
                      );

                      onForgotPassword();
                    }}
                    css={styles.forgotPassword}
                  >
                    {t("forms.linkForgotPassword")}
                  </a>
                </Form.Group>
              ) : (
                <Form.Group css={styles.checkBox}>
                  <Form.Check
                    custom
                    isInvalid={
                      touched.acceptConditions && !!errors.acceptConditions
                    }
                    type="checkbox"
                    id="acceptConditions"
                    name="acceptConditions"
                    label={
                      <Trans
                        i18nKey="forms.acceptConditions"
                        components={{
                          privacy: <a
                            key="privacy-link"
                            target="_blank"
                            href={privacyPolicyUrl}
                            onClick={handlePrivacyPolicy}
                          />,
                          terms: <a
                            key="terms-and-conditions-link"
                            target="_blank"
                            href={termsAndConditionsUrl}
                            onClick={handleTermsAndCondition}
                          />,
                        }}
                      />
                    }
                    feedback={t("forms.errors.acceptConditionsInvalid")}
                    checked={values.acceptConditions}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "acceptConditions",
                          value: e.target.checked
                        }
                      });
                    }}
                  />
                  {errors.acceptConditions && (
                    <Form.Control.Feedback type="invalid">
                      {t("forms.errors.acceptConditionsInvalid")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              <Button
                disabled={isSubmitting}
                block
                css={styles.submitButton}
                onClick={() => {
                  triggerEvent(
                    "Purchase",
                    "Click_continue_login_registration_first_step",
                    "Email"
                  );

                  const errs = []
                  if(errors.acceptConditions){
                    errs.push(t("forms.errors.acceptConditionsInvalid"))
                  }
                  if(errors.password){
                    errs.push(t("forms.errors.passwordInvalid", { count: PASSWORD_MIN_CHARS}))
                  }
                  if(errors.email){
                    errs.push(t("forms.errors.emailInvalid"))
                  }

                  if (errs.length > 0) {
                    triggerEvent(
                      "Purchase",
                      "Error_login_registration_first_step",
                      errs
                    );
                    showModal(t(errs));
                    return;
                  }
                  submitForm();
                }}
              >
                {t("flow.continue")}
              </Button>
              <Form.Group>
                <div css={styles.separator}>{t("Or")}</div>
              </Form.Group>
            </Container>
            <Form.Group css={styles.fbButtonContainer}>
              {facebookLoginAvailable && (
                <LoginSocialButton
                  icon={"facebookF"}
                  provider={"facebook"}
                  label={t("forms.loginWithFacebook")}
                  onClick={handleFbLoginClick}
                />
              )}
              {!hideGoogleSignInButton && (
                <div id="g_id_signin" className="g_id_signin btn social-login"></div>
              )}
              {googleLoginAvailable && (
                <LoginSocialButton
                  icon={"google"}
                  provider={"google"}
                  label={t("forms.loginWithGoogle")}
                  viewBox={"0 0 48 48"}
                  onClick={handleGoogleLoginClick}
                />
              )}
              {appleLoginAvailable && (
                <LoginSocialButton
                  icon={"apple"}
                  provider={"apple"}
                  label={t("forms.loginWithApple")}
                  viewBox={"0 0 1000 1188"}
                  onClick={handleAppleLoginClick}
                />
              )}
              <div className="fb-share-policy">
                {t("reservation.fbSharePolicy")}
              </div>
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
}

export default withErrorModal(AuthEmailSocial);
