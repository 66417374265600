import api from "../../../api";

const REMOVE_ITEM_FROM_CART_API = "REMOVE_ITEM_FROM_CART_API"

const removeItemFromCartApi = (itemId) => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: REMOVE_ITEM_FROM_CART_API,
    payload: api.shoppingCart.removeCartItem({
      itemId,
      token: state.user.token,
      entityId: state.entity.entityId,
      language: state.context.language,
      sessionId: state.context.sessionId,
    })
  })
}

removeItemFromCartApi.action = REMOVE_ITEM_FROM_CART_API

export default removeItemFromCartApi
