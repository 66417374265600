import React from "react";
import { Button } from "react-bootstrap";
import { css } from "@emotion/core";

const styles = {
  button: css({
    textTransform: "uppercase",
  }),
}

export default function MButton({ onClick, variant, disabled, label }) {
  return (
    <Button
      // css={[styles.button]}
      variant={variant || "m-button"}
      onClick={onClick}
      disabled={disabled}
      block
    >
      {label}
    </Button>
  );
}
