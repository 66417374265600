import React, {useCallback, useEffect, useState} from "react"
import {css} from "@emotion/core"
import {useDispatch, useSelector} from "react-redux"
import {Container, Spinner} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import selectors from "lib/redux/selectors"
import cssVars from "styles/variables.module.scss"
import withErrorModal from "../../lib/hocs/with-error-modal"
import {trackFbEvent, triggerEvent} from "../../lib/analytics"
import actions from "../../lib/redux/actions"
import * as Sentry from "@sentry/nextjs"
import {IconWrapper} from "../icons"
import MButton from "../elements/m-button"
import {navigateTo, openUrl} from "../../lib/helpers/inter-frame-communication"
import {getLocalizedUserWinPrizesUri} from "../../lib/helpers/i18n"

const styles = {
  container: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0',
    '.header': {
      color: cssVars.green,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: '0.4rem',
      padding: '3rem',
    },
    ".icon": {
      width: "6rem",
      height: "6rem",
      color: cssVars.green,
    },
    '.body': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      padding: '3rem',
      backgroundColor: cssVars.green,
      '.subtitle': {
        fontSize: '1.1rem',
      },
      ".title": {
        fontSize: '1.5rem',
        fontWeight: 'bold',
      }
    },
    '.footer': {
      width: '100%',
      '.next-step-btn-wrapper': {
        padding: '0.6rem',
        '.next-step-btn-container': {
          padding: '0.4rem 0',
        }
      }
    },
  }),
  spinnerContainer: css({
    position: "absolute",
    zIndex: 10,
    width: '100%',
    minHeight: '200px',
    maxHeight: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,.8)",
  }),
}

/**
 * Flow Step 0 for Takeaway&Delivery and Product checkout
 */
function FlowStepCampaignOfferCodeConfirmation({
                                     stepState,
                                     updateStepState,
                                     onFormikProps,
                                     onNextStep,
                                     showModal,
                                   }) {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const loggedIn = useSelector(selectors.user.loggedIn)
  const flow = useSelector((state) => state.flow.steps)
  const context = useSelector((state) => state.context)
  const entityEnv = useSelector(selectors.entity.env)

  const user = useSelector((state) => state.user)

  const { points, premiumPoints } = useSelector(selectors.product.info)
  const campaign = useSelector((state) => state.campaign.info)

  const [isLoading, setIsLoading] = useState(true)

  const handleValidSubmit = async () => {
    // Perform the actual reservation/booking HTTP request
    const {
      name,
      email,
      prefix,
      phone,
    } = flow

    const nameParts = name.split(/\s+/)
    const userInformation = {
      // first name part
      givenName: nameParts.shift(),
      // all remaining name parts
      familyName: nameParts.join(" "),
      email: loggedIn ? user.username : email,
      language: user.notificationsLanguage,
      partnerUserId: "", // TODO
      mobileNumber: {
        dialPrefix: String(prefix).replace(/[-\s]/g, ""),
        number: String(phone).replace(/\s/g, ""),
      },
    }

    const bookingPayload = {
      campaignId: campaign.id,
      refererLink: document.referrer,
      userInformation,
      partySize: 1,
      slots: [
        {
          startTime: (new Date()).toISOString(),
          durationSec: 0,
        }
      ],
      onBehalfOfUser: true, // TODO
    }

    try {
      triggerEvent("Purchase", "submit_booking_form")

      const response = await dispatch(
        actions.product.book({
          // In this special scenario we send the clientPlatform's value (WEB,
          // Android or ios) in the clientApplication in order to inform the
          // back-end if the booking was made using the mobile apps or the web app.
          clientApplication: context.clientPlatform || context.clientApplication,
          providerId: context.providerId,
          channelManager: context.channelManager,
          payload: bookingPayload,
        })
      )

      setIsLoading(false)

      const { valueCharged, transactionId } = response.action.payload.data
      triggerEvent("Purchase", "successful_booking", null, {
        eventValue: parseInt(valueCharged),
        dimension10: transactionId,
        metric1: valueCharged,
        metric6: points,
      })

      //FIXME: temp analytics v4 - improve
      window.gtag("event", "purchase", {
        transaction_id: transactionId,
        value: valueCharged,
        currency: "EUR",
        items: [
          {
            item_id: `campaign_${campaign.id}`,
          },
        ]
      })

      trackFbEvent("Purchase", {
        currency: "EUR",
        value: valueCharged,
        order_id: transactionId,
      })

    } catch (err) {
      Sentry.captureException(err);
      triggerEvent("Purchase", "error_after_submit_form", err.message)
      showModal(err.message)
    }

    return false
  }

  useEffect(() => {
    handleValidSubmit()
  }, [])

  onFormikProps({
    onBack: false,
    hideBack: true,
    // Disable the global "submit to next step" button
    submitForm: null,
    submitLabel: null,
  })

  const goToHomepage = async () => {
    try {
      await openUrl(entityEnv.frontendUrl)
    } catch {
      window.open(entityEnv.frontendUrl, '_self').focus()
    }
  }

  const viewRewardsUrl = `${entityEnv.frontendUrl}${getLocalizedUserWinPrizesUri()}`;

  const handleViewRewards = useCallback(async (event) => {
    try {
      await navigateTo(getLocalizedUserWinPrizesUri());
    } catch {
      window.open(viewRewardsUrl, '_self').focus();
    }
  }, [viewRewardsUrl]);

  return (
    <>
      {isLoading && (
        <div css={styles.spinnerContainer}>
          <Spinner animation="border" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </Spinner>
        </div>
      )}
      {!isLoading && (
        <Container css={styles.container}>
          <div className="w-100">
            <div className="header">
              <p>{t('summary.obtainedCampaign')}</p>
              <IconWrapper name="mdiCheckCircle" size="2.25rem" />
            </div>

            <div className="body">
              <p className="subtitle">{t('summary.won')}</p>
              <p className="title">{points} {t('campaignInfo.points')}!</p>
            </div>
          </div>
          <div className="footer">
            <div className="next-step-btn-wrapper">
              {/*<div className="next-step-btn-container">
                <MButton
                  label={t('summary.actionSeeRewards')}
                  onClick={handleViewRewards}
                />
              </div>*/}
              <div className="next-step-btn-container">
                <MButton
                  label={t('summary.actionHomePage')}
                  onClick={goToHomepage}
                />
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

export default withErrorModal(FlowStepCampaignOfferCodeConfirmation)
