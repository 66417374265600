import api from "lib/api"

const APPLY_COUPON_CODE = "APPLY_COUPON_CODE"

const applyCouponCode = (couponCode) => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: APPLY_COUPON_CODE,
    payload: api.shoppingCart.applyCouponCode({
      couponCode,
      sessionId: state.context.sessionId,
      entityId: state.entity.entityId,
      token: state.user.token,
      language: state.context.language,
    })
  })
}

applyCouponCode.action = APPLY_COUPON_CODE

export default applyCouponCode
