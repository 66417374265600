import api from "lib/api";
import { setLoggedInUser } from "lib/helpers/inter-frame-communication";

const USER_CHECK_EMAIL_REGISTERED = "USER_CHECK_EMAIL_REGISTERED";

const checkEmailRegistered = (email) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: USER_CHECK_EMAIL_REGISTERED,
    payload: api.user.checkEmailRegistered({
      email,
      entityId: state.entity.entityId,
    })
  })
}

checkEmailRegistered.action = USER_CHECK_EMAIL_REGISTERED;

export default checkEmailRegistered;
