import api from "lib/api"

const SHOPPING_CART_FETCH = "SHOPPING_CART_FETCH"

const fetchShoppingCart = ({ token, sessionId }) => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: SHOPPING_CART_FETCH,
    payload: api.shoppingCart.getShoppingCart({
      sessionId,
      entityId: state.entity.entityId,
      token: token || state.user.token,
      language: state.context.language,
    })
  })
}

fetchShoppingCart.action = SHOPPING_CART_FETCH

export default fetchShoppingCart
