module.exports = ({fetch}) => {
  return {
    get: ({campaignId, entityId, language}) =>
      fetch(`/mygon-middleware/rest/v1/web/campaigns/${campaignId}`, {
        method: "get",
        queryParams: {language,entityId},
      }),
    getByOfferCode: ({offerCode, entityId, language}) =>
      fetch(`/mygon-middleware/rest/v1/web/campaigns/by-offer-code/${offerCode}`, {
        method: "get",
        queryParams: {language, entityId},
      }),
    getSchedule: ({campaignId, language}) =>
      fetch(`/mygon-middleware/rest/campaign/schedule/${campaignId}`, {
        method: "get",
        queryParams: {language},
      }),
    getCalendarSlots: (
      {
        campaignId,
        startDate = new Date(),
        untilMinutes = 43200, // 30 days
        language
      }) =>
      fetch(
        `/mygon-middleware/rest/v4/campaigns/${campaignId}/calendar/available-slots?startPeriod=${startDate.toISOString()}&untilMinutes=${untilMinutes}`,
        {
          method: "get",
          queryParams: {language},
        }
      ),
    book: ({userId, token, payload, clientApplication, language, channelManager}) =>
      fetch(`/mygon-middleware/rest/client/book/book/${userId}`, {
        method: "post",
        // credentials: "include",
        queryParams: {token, clientApplication, language, channelManager},
        body: JSON.stringify(payload)
      }),
    requestSms: ({userId, token, language, payload}) =>
      fetch(`/mygon-middleware/rest/client/action/sms/send/${userId}`, {
        method: "post",
        // credentials: "include",
        queryParams: {token, language},
        body: JSON.stringify(payload)
      }),
    /**
     * Generate payment, returns hipay url payment page
     *
     * @param options
     * @param options.payload
     * @param options.payload.expireDate: "2020-01-20T00:00:00+00:00"
     * @param options.payload.invoiceName: ""
     * @param options.payload.invoiceFiscalId: ""
     * @param options.payload.onBehalfOfName: "undefined"
     * @param options.payload.campaignId: "512"
     * @param options.payload.campaignTypeId: 5
     * @param options.payload.quantityPurchased: 1
     * @param options.payload.returnUrl:
     * "https://www.mygon.com/pt/campanha/512/mygon-testes--cupao-gratis"
     */
    getVoucherHiPayPaymentLink: ({userId, token, clientApplication, language, payload}) =>
      fetch(`/mygon-middleware/rest/client/gift/hipay/${userId}/`, {
        method: "post",
        queryParams: {token, clientApplication, language},
        body: JSON.stringify(payload)
      }),
    getGuestVoucherHiPayPaymentLink: ({clientApplication, providerId, channelManager, language, payload}) =>
      fetch(`/mygon-middleware/rest/client/gift/hipay/guest/`, {
        method: "post",
        queryParams: {clientApplication, providerId, channelManager, language},
        body: JSON.stringify(payload)
      }),
    getPremiumPlans: ({userId, token}) =>
      fetch(`/mygon-middleware/rest/client/premium/code/hipay/${userId}`, {
        method: "get",
        queryParams: {token}
      })
  };
};
