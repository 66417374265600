import React, { useCallback, useMemo, useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { css } from "@emotion/core";
import { Alert, Container, Form } from "react-bootstrap";
import { navigateTo } from "lib/helpers/inter-frame-communication";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation, Trans } from "react-i18next";
import { triggerEvent } from "lib/analytics";
import withErrorModal from "lib/hocs/with-error-modal";
import {getLocalizedPrivacyPolicyUri, getLocalizedTermsAndConditionsUri} from "lib/helpers/i18n"
import actions from "lib/redux/actions";
import selectors from "lib/redux/selectors";
import cssVars from "styles/variables.module.scss";
import Title from "./title";

const PASSWORD_MIN_CHARS = 6;

const styles = {
  checkBox: css({
    fontSize: "0.8rem",
    color: cssVars.gray600
  }),
  email: css({
    fontSize: "1.1rem",
    fontWeight: "bold",
    textAlign: "center",
    color: cssVars.primary,
    marginBottom: "0.5rem"
  }),
  forgotPassword: css({
    color: cssVars.gray800,
    textDecoration: "underline"
  })
};

const loginPasswordSchema = yup.string().required();
const registerPasswordSchema = yup
  .string()
  .min(PASSWORD_MIN_CHARS)
  .required();
const acceptConditionsSchema = yup.bool().oneOf([true]);

function AuthLoginRegister({
  isLogin,
  email,
  forgotPasswordComplete = false,
  onBack,
  onComplete,
  onForgotPassword,
  onFormikProps,
  showModal
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authTypeLabel = isLogin ? "Login_page" : "Registration_page";
  const [showForgotPasswordComplete, setShowForgotPasswordComplete] = useState(
    forgotPasswordComplete
  );
  const referralCode = useSelector(selectors.flow.referralCode);
  const isCordovaAppClient = useSelector(selectors.context.isCordovaAppClient);

  useEffect(
    function init() {
      if (isLogin) {
        triggerEvent("Purchase", "open_login_form_booking_widget");
      } else {
        triggerEvent("Purchase", "open_registration_step_booking_widget");
      }
      triggerEvent(
        "Purchase",
        "Open_login_registration_second_step",
        authTypeLabel
      );
    },
    [isLogin]
  );

  const handleValidSubmit = async ({ password }) => {
    if (isLogin) {
      try {
        const response = await dispatch(
          actions.user.login({
            username: email,
            password,
            persistence: true
          })
        );
        const { id, districtId, genderId } = response.action.payload.data;
        triggerEvent("Purchase", "successful_login_booking_widget", "mygon", {
          dimension5: districtId,
          dimension6: id,
          dimension7: genderId
        });
        triggerEvent(
          "Purchase",
          "Successful_login_registration_booking_widget",
          "Email_login"
        );
      } catch (err) {
        showModal(err.message);
        triggerEvent("Purchase", "error_login_booking_widget", err.message);
        return false;
      }
    } else {
      try {
        const response = await dispatch(
          actions.user.register({
            username: email,
            password,
            referralCode,
          })
        );
        const { id } = response.action.payload.data;
        const { districtId } = response.action.meta;
        // Since the API doesn't return the logged in user token, we actually
        // have to log in the user after signup. :/
        await dispatch(
          actions.user.login({
            username: email,
            password,
            persistence: true
          })
        );
        triggerEvent(
          "Purchase",
          "successful_registration_booking_widget",
          "mygon",
          {
            dimension5: districtId,
            dimension6: id
          }
        );
        triggerEvent(
          "Purchase",
          "Successful_login_registration_booking_widget",
          "Email_registration"
        );
      } catch (err) {
        showModal(err.message);
        triggerEvent(
          "Purchase",
          "error_registration_booking_widget",
          err.message
        );
        return false;
      }
    }

    onComplete();
    return false;
  };

  const schema = useMemo(
    () =>
      yup.object({
        password: isLogin ? loginPasswordSchema : registerPasswordSchema,
        acceptConditions: isLogin ? undefined : acceptConditionsSchema
      }),
    [isLogin]
  );

  const privacyPolicyUrl = `${entityEnv.frontendUrl}${getLocalizedPrivacyPolicyUri()}`;

  const termsAndConditionsUrl = `${entityEnv.frontendUrl}${getLocalizedTermsAndConditionsUri()}`

  const handlePrivacyPolicy = useCallback(async (event) => {
    if (isCordovaAppClient) {
      event.preventDefault();
      try {
        await navigateTo(getLocalizedPrivacyPolicyUri());
      } catch {
        window.open(privacyPolicyUrl, '_blank').focus();
      }
    }
  }, [privacyPolicyUrl]);

  const handleTermsAndCondition = useCallback(async (event) => {
    if (isCordovaAppClient) {
      event.preventDefault();
      try {
        await navigateTo(getLocalizedTermsAndConditionsUri());
      } catch {
        window.open(termsAndConditionsUrl, '_blank').focus();
      }
    }
  }, [termsAndConditionsUrl]);

  return (
    <Container>
      <Formik
        validateOnMount
        validationSchema={schema}
        onSubmit={handleValidSubmit}
        initialValues={{ password: "", acceptConditions: false }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          submitForm,
          setFieldTouched,
          isValid,
          values,
          touched,
          errors,
          isSubmitting
        }) => {
          onFormikProps({
            onBack,
            submitForm: () => {
              setFieldTouched("acceptConditions");

              triggerEvent(
                "Purchase",
                "Click_continue_login_registration_second_step",
                authTypeLabel
              );

              const errs = Object.values(errors).join(",");
              if (errs) {
                triggerEvent(
                  "Purchase",
                  "Error_login_registration_second_step",
                  errs
                );
                if (errors.password) {
                  showModal(
                    t("forms.errors.passwordInvalid", {
                      count: PASSWORD_MIN_CHARS
                    })
                  );
                } else if (errors.acceptConditions) {
                  showModal(
                    t("forms.errors.acceptConditionsInvalid")
                  );
                }
                return;
              }

              submitForm();
            },
            submitLabel: isLogin ? t("flow.login") : t("flow.register"),
            isValid: true,
            isSubmitting
          });
          return (
            <Form
              noValidate
              className="form padded-step-container"
              onSubmit={handleSubmit}
            >
              {showForgotPasswordComplete && (
                <Alert
                  variant="success"
                  onClose={() => setShowForgotPasswordComplete(false)}
                  dismissible
                >
                  {t("forms.forgotPasswordEmailSent")}
                </Alert>
              )}
              <Form.Group>
                <Title
                  icon={
                    isLogin ? "mdiLoginVariant" : "mdiAccountCardDetailsOutline"
                  }
                >
                  {t(isLogin ? "forms.titles.login" : "forms.titles.register")}
                </Title>
              </Form.Group>
              <Form.Group>
                <div css={styles.email}>{email}</div>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  className="rounded-underline"
                  type="password"
                  name="password"
                  placeholder={t("forms.password")}
                  autoFocus
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {isLogin
                      ? t("forms.errors.passwordLogin")
                      : t("forms.errors.passwordRegister", {
                          count: PASSWORD_MIN_CHARS,
                        })}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {isLogin ? (
                <Form.Group>
                  <a
                    href="#"
                    onClick={() => {
                      triggerEvent(
                        "Purchase",
                        "Click_forget_my_password_booking_widget"
                      );

                      onForgotPassword();
                    }}
                    css={styles.forgotPassword}
                  >
                    {t("forms.linkForgotPassword")}
                  </a>
                </Form.Group>
              ) : (
                <Form.Group css={styles.checkBox}>
                  <Form.Check
                    custom
                    isInvalid={
                      touched.acceptConditions && !!errors.acceptConditions
                    }
                    type="checkbox"
                    id="acceptConditions"
                    name="acceptConditions"
                    label={
                      <Trans
                        i18nKey="forms.acceptConditions"
                        components={{
                          privacy: <a
                            key="privacy-link"
                            target="_blank"
                            href={privacyPolicyUrl}
                            onClick={handlePrivacyPolicy}
                          />,
                          terms: <a
                            key="terms-and-conditions-link"
                            target="_blank"
                            href={termsAndConditionsUrl}
                            onClick={handleTermsAndCondition}
                          />,
                        }}
                      />
                    }
                    feedback={t("forms.errors.acceptConditionsInvalid")}
                    checked={values.acceptConditions}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}

export default withErrorModal(AuthLoginRegister);
