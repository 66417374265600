import {DeliveryTypes} from "../../helpers/constants"

const selectors = {};

selectors.deliveryTypes = (state) => {
  const shops = state.deliveries.deliveryMethods

  return shops.flatMap((shop) => Object.keys(shop.methods))
    .filter((v, i, a) => a.indexOf(v) === i)
}

selectors.types = (state) => {
  const shops = state.deliveries.deliveryMethods

  // accumulate delivery types for all shops

  const allDeliveryTypes = selectors.deliveryTypes(state)

  const deliveryTypes = allDeliveryTypes.map(type => {
    // a delivery type is only enabled if available for all shops
    const unavailableForShops = shops.filter(shop => !shop.methods[type] || shop.methods[type].length === 0)
      .map(shop => ({
        shopName: shop.name,
      }))

    const typeDeliveryMethods = shops.reduce((acc, shop) => {
      if (!shop.methods[type]) {
        return acc
      }
      return [...acc, ...shop.methods[type]]
    }, [])

    const startingPrice = typeDeliveryMethods.reduce((acc, method) => Math.min(acc, method.costWithVat), Infinity)
    const hasHigherPrice = typeDeliveryMethods.some(method => method.costWithVat > startingPrice)

    return {
      type,
      disabledFor: unavailableForShops,
      disabled: unavailableForShops.length > 0,
      price: startingPrice,
      startingFrom: typeDeliveryMethods.length > 1 && hasHigherPrice,
    }
  })

  return deliveryTypes.sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1
    }
    if (!a.disabled && b.disabled) {
      return -1
    }

    return a.startingFrom - b.startingFrom
  })
}

selectors.countries = (state) => {
  return state.deliveries.deliveryCountries
}

// all delivery methods groped by deliveryType
selectors.availableDeliveryMethods = (state) => {
  return state.deliveries.deliveryMethods
}

selectors.pickupPoints = (state) => {
  return state.deliveries.pickupPoints
}

// all delivery methods in a list
selectors.allDeliveryMethods = (state) => {
  return state.deliveries.deliveryMethods.flatMap(shop => {
    //flatten with shop info
    return Object.keys(shop.methods).flatMap(type => shop.methods[type].map(method => ({
      ...method,
      shopId: shop.id,
      shopName: shop.name,
      shopAddress: shop.shopAddress,
    })))
  })
}

// final delivery method rates for delivery address, otherwise, for pickup it's not necessary
selectors.deliveryMethodRates = (state) => {
  const allDeliveryMethods = selectors.allDeliveryMethods(state)
  const productOrder = state.flow.steps.productOrder
  const methodsByShop = state.deliveries.deliveryRates
  const deliveryType = state.flow.steps.deliveryType

  return methodsByShop.map(shop => {
    const available = !!shop.methods[deliveryType]
    const productOrderShop = productOrder[shop.id]
    const deliveryMethod = allDeliveryMethods.find(method => method.id === productOrderShop.shopDeliveryMethodId)

    return {
      shopId: shop.id,
      shopName: shop.name,
      shopAddress: shop.shopAddress,
      available,
      methods: available ? shop.methods[deliveryType] : [],
      id: deliveryMethod ? deliveryMethod.id : null,
      price: deliveryMethod ? deliveryMethod.costWithVat : null,
    }
  }).sort((a, b) => a.shopId - b.shopId)
}

selectors.deliveryCost = (state) => {
  const deliveryMethods = selectors.deliveryMethodRates(state)
  return deliveryMethods.reduce((acc, method) => acc + method.price, 0)
}

selectors.selectedDeliveryMethods = (state) => {
  const productOrder = state.flow.steps.productOrder
  const allDeliveryMethods = selectors.allDeliveryMethods(state)
  const selectedDeliveryMethods = {}

  Object.keys(productOrder).forEach(shopId => {
    const shopOrder = productOrder[shopId]
    const deliveryMethod = allDeliveryMethods.find(method => method.id === shopOrder.shopDeliveryMethodId)
    if (deliveryMethod) {
      selectedDeliveryMethods[shopId] = {
        ...deliveryMethod,
        note: shopOrder.note,
      }
    }
  })

  return selectedDeliveryMethods
}

selectors.pickupPointDeliveryMethodIds = (state) => {
  return Object.values(selectors.allDeliveryMethods(state)).filter(method => method.deliveryType === DeliveryTypes.PICKUP_POINT).map(method => method.id)
}

selectors.selectedDeliveryMethodIds = (state) => {
  return Object.values(selectors.selectedDeliveryMethods(state)).map(method => method.id)
}

selectors.availablePaymentMethods = (state) => {
  const selectedDeliveryMethods = selectors.selectedDeliveryMethods(state)

  // flatten delivery methods
  const deliveryMethods = Object.values(selectedDeliveryMethods)

  const acceptsOnlinePayment = deliveryMethods.every(method => method.acceptsOnlinePayment)
  const acceptsOfflinePayment = deliveryMethods.every(method => method.acceptsOfflinePayment)

  const paymentMethods = {
    online: acceptsOnlinePayment,
    offline: acceptsOfflinePayment,
  }

  return Object.keys(paymentMethods)
    .filter(key => paymentMethods[key])
}

export default selectors
