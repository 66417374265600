import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/core";
import {Container, Row, Col, Spinner, InputGroup, Form, Button} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import withErrorModal from "lib/hocs/with-error-modal";
import { triggerEvent } from "lib/analytics";
import ReservationSummaryHeading from "components/elements/reservation-summary-heading";
import { PaymentMethods, AllPaymentMethods } from "lib/helpers/constants";
import cssVars from "styles/variables.module.scss";
import Image from "next/image";
import MbwayPayment from "./payment-methods/mbway-payment";
import HipayMbPayment from "./payment-methods/hipay-mb-payment";
import HipayCcPayment from "./payment-methods/hipay-cc-payment";
import MbwayWaitingConfirmation from "./payment-methods/mbway-waiting-confirmation";
import actions from "lib/redux/actions";

const styles = {
  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  spinnerOverlay: css({
    position: "fixed",
    zIndex: 100,
    width: "100%",
    height: "80%", // reduce to account for summary header
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,.8)",
  }),
  paymentMethod: css({
    position: "relative",
    backgroundColor: cssVars.gray300,
    margin: "10px",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: "10px",
    minHeight: "90px",
    cursor: "pointer",
    border: '2px solid transparent',
    "&.active": {
      border: `2px solid ${cssVars.primary}`
    },
    "&.hipay_cc > div": {
      maxWidth: "128px",
    },
    "& > div": {
      margin: "16px !important",
      marginRight: "auto !important",
      marginLeft: "auto !important",
      maxHeight: "80px",
      maxWidth: "90px",
    },
  }),
  paymentComponentContainer: css({
    marginTop: "32px",
  }),
};

/**
 * Flow Step 3 - Payment method
 */
function VoucherPayment({ onFormikProps, stepState, updateStepState, onPrevStep, onNextStep, showModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [paymentComponent, setPaymentComponent] = useState(null)
  const [waitingMbwayConfirmation, setWaitingMbwayConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const selectedPaymentMethod = useSelector((state) => state.payment.selectedPaymentMethod);


  useEffect(function init() {
    triggerEvent("Purchase", "buy_as_gift_open_confirmation_page");
  }, []);

  const onFinish = async () => {
    setLoading(false)
    if (selectedPaymentMethod === PaymentMethods.HIPAY_MBWAY) {
      setWaitingMbwayConfirmation(true)
    } else {
      onNextStep()
    }
  }

  const onPaymentRequest = async () => {
    setLoading(true)
  }

  const onPaymentFailed = async () => {
    setLoading(false)
  }

  const selectPaymentMethod = (paymentMethod) => {
    dispatch(actions.payment.selectPaymentMethod(paymentMethod))
  }

  useEffect(() => {
    if (selectedPaymentMethod === PaymentMethods.HIPAY_MBWAY) {
      setPaymentComponent(
        <MbwayPayment
          stepState={stepState}
          updateStepState={updateStepState}
          onFormikProps={onFormikProps}
          onFinish={onFinish}
          showModal={showModal}
          onPaymentRequest={onPaymentRequest}
          paymentFailed={onPaymentFailed}
        />
      )
    } else if (selectedPaymentMethod === PaymentMethods.HIPAY_CC) {
      setPaymentComponent(
        <HipayCcPayment
          onFormikProps={onFormikProps}
          showModal={showModal}
        />
      )
    } else if (selectedPaymentMethod === PaymentMethods.HIPAY_MB) {
      setPaymentComponent(
        <HipayMbPayment
          onFormikProps={onFormikProps}
          showModal={showModal}
        />
      )
    }
  }, [selectedPaymentMethod])

  return (
    <div>
      <ReservationSummaryHeading onClick={onPrevStep} />
      {(waitingMbwayConfirmation && !loading) && (
        <Container>
          <div className="padded-step-container">
            <MbwayWaitingConfirmation
              onFormikProps={onFormikProps}
              updateStepState={updateStepState}
              stepState={stepState}
              onNextStep={onNextStep}
              showModal={showModal}
            />
          </div>
        </Container>
      )}
      {loading && (
        <div css={styles.container}>
          <div css={styles.spinnerOverlay}>
            <Spinner animation="border" role="status">
              <span className="sr-only">{t("waiting")}...</span>
            </Spinner>
          </div>
        </div>
      )}
      {(!waitingMbwayConfirmation && !loading) && (
        <Container>
          <div className="padded-step-container">
            <p className="large-text">{t('paymentMethods.title')}</p>
            <Row className="no-gutters">
              {AllPaymentMethods.map(paymentMethod => (
                <Col
                  xs={4}
                  key={paymentMethod}
                  onClick={() => selectPaymentMethod(paymentMethod)}
                >
                  <div
                    css={styles.paymentMethod}
                    className={[
                      paymentMethod,
                      selectedPaymentMethod === paymentMethod ? "active" : "",
                    ].filter(Boolean)
                      .join(" ")}
                  >
                    {paymentMethod === PaymentMethods.HIPAY_MB && (
                      <Image
                        src="https://www.mygon.com/reservation-widget/static/images/payment-methods/hipay_mb.png"
                        layout='fill'
                        objectFit='contain'
                      />
                    )}
                    {paymentMethod === PaymentMethods.HIPAY_MBWAY && (
                      <Image
                        src="https://www.mygon.com/reservation-widget/static/images/payment-methods/hipay_mbway.png"
                        layout='fill'
                        objectFit='contain'
                      />
                    )}
                    {paymentMethod === PaymentMethods.HIPAY_CC && (
                      <Image
                        src="https://www.mygon.com/reservation-widget/static/images/payment-methods/visa_mastercard.png"
                        layout='fill'
                        objectFit='contain'
                      />
                    )}
                  </div>
                </Col>
              ))}
            </Row>
            <div css={styles.paymentComponentContainer}>
              {!!paymentComponent && paymentComponent}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}

export default withErrorModal(VoucherPayment);
