import api from "lib/api"

const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"

const getPaymentMethods = () => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: GET_PAYMENT_METHODS,
    payload: api.shoppingCart.getPaymentMethods({
      sessionId: state.context.sessionId,
      entityId: state.entity.entityId,
      token: state.user.token,
      language: state.context.language,
    })
  })
}

getPaymentMethods.action = GET_PAYMENT_METHODS

export default getPaymentMethods
