export function img(options) {
  const url = options.url

  if (!url) {
    return
  }

  const baseCdn = process.env.IMG_CDN_URL
  const height = options.height || 250
  const width = options.width || 250
  const smartPosition = options.smart || false
  const resizeType = !options.rs ? 'fill' : options.rs

  // images in static are scaled to 160x160 by default without parameters, so we'll keep using it's own image processor
  // to avoid duplicating the processing in static + imgproxy
  if (url.includes('static.mygon.com')) {
    return `${url}?width=${width}&height=${height}`
  }

  if (smartPosition) {
    return `${baseCdn}/rs:${resizeType}:${width}:${height}:1/f:webp/g:sm/plain/${options.url}`
  }

  return `${baseCdn}/rs:${resizeType}:${width}:${height}:1/f:webp/plain/${options.url}`
}
